import React, {useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {graphql, navigate} from 'gatsby';
import {AnimatePresence} from 'framer-motion';
import _ from 'lodash';
// import _ from 'lodash';
// import PropTypes from 'prop-types';
import Spacer from '../../components/Spacer';
import ContentContainer from '../../components/ContentContainer';
import ContactForm from '../../components/ContactForm';
import SectionHeader from '../../components/SectionHeader';
import Button from '../../components/Button';
import PageDivider from '../../components/PageDivider';
import constants from '../../constants';
import Utils from '../../util/util';

const Contact = ({data, location}) => {
  const {t} = useTranslation();

  const queryOptions = useMemo(
    () => [
      {
        value: constants.QUERY_TYPE.GENERAL,
        label: t('dropdown.type-of-query.general'),
      },
      {
        value: constants.QUERY_TYPE.CAR_PURCHASE,
        label: t('dropdown.type-of-query.car-purchase'),
      },
      {
        value: constants.QUERY_TYPE.AUTO_PARTS_PURCHASE,
        label: t('dropdown.type-of-query.auto-parts-purchase'),
      },
    ],
    [t]
  );

  const defaultQueryType = _.find(queryOptions, (i) => {
    return (
      i.value ===
      _.get(location, 'state.queryType', constants.QUERY_TYPE.GENERAL)
    );
  });
  const defaultMessage = _.get(location, 'state.message', '');

  const [isSent, setisSent] = useState(false);

  // const mockSent = () => {
  //   setisLoading(true);
  //   window.scrollTo({top: 0, behavior: 'smooth'})
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 4000)
  //   })
  //     .then(() => {
  //       setisLoading(false);
  //       setisSent(true);
  //     })
  // }

  const _renderContent = () => {
    if (isSent) {
      return (
        <>
          <SectionHeader
            showHeader={isSent}
            title={t('pages.contact-us.message-sent-msg.title')}
            subTitle={t('pages.contact-us.message-sent-msg.sub-text')}
          />
          <Button title={t('button.home')} onClickBtn={() => navigate('/')} />
        </>
      );
    }

    return (
      <ContactForm
        defaultMessage={defaultMessage}
        defaultQueryType={defaultQueryType}
        onIsSent={() => setisSent(true)}
        isSent={isSent}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Auto Savvy HK | Contact Us</title>
      </Helmet>
      <Spacer />
      <PageDivider image1={Utils.getGatsbyImageData(data)} />
      <div className={'py-10 lg:py-20'}>
        <ContentContainer className={'min-h'}>
          <AnimatePresence exitBeforeEnter>{_renderContent()}</AnimatePresence>
        </ContentContainer>
      </div>
    </>
  );
};

export default Contact;

export const query = graphql`
  query MyQuery {
    file(relativePath: {eq: "contact.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`;

Contact.propTypes = {};
Contact.defaultProps = {};
