import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import './loading.scss';

const Loading = () => {
  return (
    <AnimatePresence>
      <motion.div
        className={`self-center`}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 1}}
      >
        <div className="loadingio-spinner-pulse-l59va2ceen">
          <div className="ldio-199rnarl3vr">
            <div />
            <div />
            <div />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;
