import React, {useMemo} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import Textarea from '../Input/Textarea';
import Text from '../Typograhpy/Text';

const TextareaField = ({
  label,
  value,
  onValueChange,
  OutterClass,
  validateErrObj,
}) => {
  return (
    <motion.div layout className={`w-full ${OutterClass}`}>
      <Text value={label} className={'text-primary'} />
      <Textarea value={value} onValueChange={onValueChange} />
      {useMemo(
        () => (
          <AnimatePresence>
            {validateErrObj?.msg && (
              <Text
                animated
                fieldErr
                value={validateErrObj.msg}
                initial={{
                  y: -30,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  y: -30,
                  opacity: 0,
                }}
              />
            )}
          </AnimatePresence>
        ),
        [validateErrObj]
      )}
    </motion.div>
  );
};

export default TextareaField;

TextareaField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  OutterClass: PropTypes.string,
  onValueChange: PropTypes.func,
  validateErrObj: PropTypes.object,
};
TextareaField.defaultProps = {
  label: '',
  value: '',
  OutterClass: '',
  onValueChange: () => {},
  validateErrObj: {},
};
