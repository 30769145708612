import React, {useState, useMemo} from 'react';
import _ from 'lodash';
import {motion, AnimatePresence} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Text from './Typograhpy/Text';
import Button from './Button';
import SectionHeader from './SectionHeader';
import InputField from './FormField/InputField';
import RadioButton from './FormField/RadioButton';
import TextareaField from './FormField/TextareaField';
import DropdownField from './FormField/DropdownField';
import PhoneInputField from './FormField/PhoneInputField';
import Loading from './Loading';
import validator from '../util/validator.util';
import AWS from '../services/AWS';
import configs from '../configs';
import constants from '../constants';

const ContactForm = ({defaultQueryType, defaultMessage, onIsSent, isSent}) => {
  const {t} = useTranslation();

  const options = useMemo(
    () => [
      {
        value: 'phone',
        label: t('radio-button.contact-me-with.phone'),
      },
      {
        value: 'email',
        label: t('radio-button.contact-me-with.email'),
      },
    ],
    [t]
  );

  const queryOptions = useMemo(
    () => [
      {
        value: constants.QUERY_TYPE.GENERAL,
        label: t('dropdown.type-of-query.general'),
      },
      {
        value: constants.QUERY_TYPE.CAR_PURCHASE,
        label: t('dropdown.type-of-query.car-purchase'),
      },
      {
        value: constants.QUERY_TYPE.AUTO_PARTS_PURCHASE,
        label: t('dropdown.type-of-query.auto-parts-purchase'),
      },
    ],
    [t]
  );

  const [fName, setfName] = useState('');
  const [lName, setlName] = useState('');
  const [phone, setphone] = useState('');
  const [email, setemail] = useState('');
  const [queryType, setqueryType] = useState(defaultQueryType);
  const [contactType, setcontactType] = useState('');
  const [message, setmessage] = useState(defaultMessage);
  const [validateErrObj, setvalidateErrObj] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [isFailed, setisFailed] = useState(false);

  const _onSubmit = (e) => {
    e.preventDefault();
    setisFailed(false);
    if (isLoading) return;
    const params = {
      fName,
      lName,
      phone,
      email,
      queryType,
      contactType,
      message,
    };
    let tempErr = {};
    _.forEach(params, (v, l) => {
      switch (l) {
        case 'fName':
          if (
            validator.isEmpty(v) ||
            validator.isIncludeSpecialChar(v) ||
            validator.isIncludeSpecialChar(v) ||
            (v.length && v.length > configs.fNameMaxCount)
          ) {
            tempErr = {
              ...tempErr,
              fName: {msg: t('errors.form.invalid-f-name')},
            };
          }
          break;

        case 'lName':
          if (
            validator.isEmpty(v) ||
            validator.isIncludeSpecialChar(v) ||
            validator.isIncludeSpecialChar(v) ||
            (v.length && v.length > configs.lNameMaxCount)
          ) {
            tempErr = {
              ...tempErr,
              lName: {msg: t('errors.form.invalid-f-name')},
            };
          }
          break;

        case 'email':
          if (
            !validator.isEmail(v) ||
            (v.length && v.length > configs.emailMaxCount)
          ) {
            tempErr = {
              ...tempErr,
              email: {msg: t('errors.form.invalid-email')},
            };
          }
          break;

        case 'phone':
          if (!validator.isValidPhone(v)) {
            tempErr = {
              ...tempErr,
              phone: {msg: t('errors.form.invalid-phone-number')},
            };
          }
          break;

        case 'message':
          const max = configs.formMessageMaxCount;
          if (
            validator.isEmptyString(message) ||
            (message.length && message.length > max)
          ) {
            tempErr = {
              ...tempErr,
              message: {msg: t('errors.form.maximum-message', {max})},
            };
          }
          break;
        default:
          break;
      }
    });

    if (_.size(tempErr) > 0) {
      setisLoading(false);
    } else {
      setisLoading(true);
      window.scrollTo({top: 0, behavior: 'smooth'});
      AWS.lambdaFunc
        .sendMailContactUs(params)
        .then((res) => {
          setisLoading(false);
          onIsSent();
          // console.log(res);
        })
        .catch((err) => {
          setisLoading(false);
          setisFailed(true);
          // console.error(err);
        });
    }
    setvalidateErrObj(tempErr);
  };

  const _renderFormBottomErrorMsg = useMemo(() => {
    return (
      _.size(validateErrObj) > 0 && (
        <Text
          fieldErr
          value={t('errors.form.review-form')}
          animated
          initial={{
            y: -30,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -30,
            opacity: 0,
          }}
        />
      )
    );
  }, [validateErrObj, t]);

  const _renderFailedText = useMemo(() => {
    return (
      isFailed && (
        <Text
          fieldErr
          value={t('errors.form.try-later')}
          animated
          initial={{
            y: -30,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -30,
            opacity: 0,
          }}
        />
      )
    );
  }, [isFailed, t]);

  const _renderFormFields = () => {
    return (
      <motion.div layout>
        <div className={'flex flex-col md:flex-row gap-2 gap-x-8'}>
          <InputField
            label={t('pages.contact-us.first-name')}
            value={fName}
            onValueChange={setfName}
            validateErrObj={validateErrObj?.fName}
          />
          <InputField
            label={t('pages.contact-us.last-name')}
            value={lName}
            onValueChange={setlName}
            validateErrObj={validateErrObj?.lName}
          />
        </div>
        <InputField
          label={t('pages.contact-us.email')}
          value={email}
          onValueChange={setemail}
          validateErrObj={validateErrObj?.email}
        />
        <PhoneInputField
          label={t('pages.contact-us.phone-number')}
          onValueChange={setphone}
          inputClass={'pl-3'}
          validateErrObj={validateErrObj?.phone}
        />
        <RadioButton
          options={options}
          label={t('pages.contact-us.contact-me-with')}
          onValueChange={setcontactType}
        />
        <DropdownField
          label={t('pages.contact-us.type-of-query')}
          onValueChange={setqueryType}
          options={queryOptions}
          defaultValue={defaultQueryType}
        />
        <TextareaField
          label={t('pages.contact-us.message')}
          value={message}
          onValueChange={setmessage}
          validateErrObj={validateErrObj?.message}
        />
        <AnimatePresence>
          {_renderFormBottomErrorMsg}
          {_renderFailedText}
        </AnimatePresence>
        <Button
          className={`mt-5`}
          onClickBtn={_onSubmit}
          title={t('button.send')}
          isLoading={isLoading}
        />
      </motion.div>
    );
  };
  return (
    <motion.form
      className={'w-full flex flex-col gap-6'}
      exit={{opacity: 0, y: -35}}
    >
      <SectionHeader title={t('pages.contact-us.contact-us')} noMargin />
      {isLoading ? (
        <div className={'w-full h-60 flex justify-center items-center'}>
          <Loading />
        </div>
      ) : (
        _renderFormFields()
      )}
    </motion.form>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  defaultQueryType: PropTypes.object,
  defaultMessage: PropTypes.string,
  onIsSent: PropTypes.func,
  isSent: PropTypes.bool,
};
ContactForm.defaultProps = {
  defaultQueryType: {},
  defaultMessage: '',
  onIsSent: () => {},
  isSent: false,
};
