import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text from '../Typograhpy/Text';

/**
 * TODO decouple input & field
 */

const RadioButton = ({options, label, onValueChange, OutterClass}) => {
  const [activeIdx, setactiveIdx] = useState(null);

  useEffect(() => {
    onValueChange(_.get(_.first(options), 'value', 'phone'));
    setactiveIdx(0);
  }, [onValueChange, options]);

  const _onClick = (value, _idx) => {
    onValueChange(value);
    setactiveIdx(_idx);
  };

  const renderButton = ({value, label}, idx) => {
    return (
      <div
        className={'flex flew-row w-24 items-center gap-1 mr-12'}
        key={`rBtn-${value}`}
        role={'button'}
        onClick={() => _onClick(value, idx)}
        onKeyPress={() => _onClick(value, idx)}
        tabIndex={0}
      >
        <div
          className={
            'border border-primary bg-transparent rounded-full w-5 h-5 flex items-center justify-center'
          }
        >
          <div
            className={`cursor-pointer bg-${
              idx === activeIdx ? 'primary' : 'transparent'
            } rounded-full w-3 h-3`}
          />
        </div>
        <Text value={label} className={`text-primary cursor-pointer`} />
      </div>
    );
  };

  return (
    <div className={`flex flex-col ${OutterClass}`}>
      <Text value={label} className={`text-primary mb-2`} />
      <div className={`flex `}>
        {_.map(options, (i, index) => renderButton(i, index))}
      </div>
    </div>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  onValueChange: PropTypes.func,
};
RadioButton.defaultProps = {
  label: '',
  onValueChange: () => {},
};
