import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Util from '../../util/util';

const DropDown = ({options, onValueChange, defaultValue, width}) => {
  const [windowWidth, setwindowWidth] = useState(0);
  const handleResize = () => {
    setwindowWidth(window.innerWidth);
  };
  // const fieldWidth = window.innerWidth < Util.breakpointSize.md ? '100%' : 300;
  useEffect(() => {
    setwindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmallScreen = () => {
    if (windowWidth < Util.breakpointSize.md) {
      return true;
    }
    return false;
  };

  return (
    <Select
      options={options}
      placeholder={'- Select -'}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: `1px solid ${Util.theme.color.primary}`,
          borderRadius: 0,
          // width: fieldWidth
        }),
        input: (styles) => ({
          ...styles,
          color: Util.theme.color.primary,
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          color: Util.theme.color.primary,
        }),
        option: (styles, {isDisabled, isFocused, isSelected}) => ({
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isFocused
            ? Util.theme.color.primary
            : isSelected
            ? Util.theme.color.primary
            : null,
          color: isFocused
            ? '#ffffff'
            : isSelected
            ? '#ffffff'
            : Util.theme.color.primary,
          opacity: isFocused ? 1 : isSelected ? 0.7 : 1,
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: '#000000',
          border: `1px solid ${Util.theme.color.primary}`,
          borderRadius: 0,
        }),
        singleValue: (styles) => ({
          ...styles,
          color: Util.theme.color.primary,
        }),
        container: (styles) => ({
          ...styles,
          width: width ? width : isSmallScreen() ? '100%' : 300,
          // border: '1px solid red',
          // ':active:': {
          //   ...styles[':active:'],
          //   border: '1px solid red'
          // }
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          backgroundColor: Util.theme.color.primary,
        }),
      }}
      onChange={onValueChange}
      defaultValue={defaultValue ? defaultValue : _.first(options)}
    />
  );
};

export default DropDown;

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  defaultValue: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
DropDown.defaultProps = {
  onValueChange: () => {},
  defaultValue: null,
};
