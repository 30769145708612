import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const Textarea = ({value, rows, onValueChange}) => {
  const _onValueChange = (e) => onValueChange(_.get(e, 'target.value', ''));

  return (
    <textarea
      className={`bg-transparent border-b border-primary outline-none w-full text-white`}
      rows={rows}
      value={value}
      onChange={_onValueChange}
      size={1000}
    />
  );
};

export default Textarea;

Textarea.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.number,
};
Textarea.defaultProps = {
  value: '',
  rows: 10,
};
