import React, {useMemo} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import Text from '../Typograhpy/Text';
import Input from '../Input/Input';

const InputField = ({
  labelClass,
  inputClass,
  OutterClass,
  value,
  onValueChange,
  label,
  validateErrObj,
}) => {
  return (
    <motion.div
      layout
      className={`flex flex-col w-full md:w-64 ${OutterClass}`}
    >
      <Text value={label} className={`text-primary ${labelClass}`} size={100} />
      <Input
        value={value}
        onValueChange={onValueChange}
        className={inputClass}
      />
      {useMemo(
        () => (
          <AnimatePresence>
            {validateErrObj?.msg && (
              <Text
                animated
                fieldErr
                value={validateErrObj.msg}
                initial={{
                  y: -30,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  y: -30,
                  opacity: 0,
                }}
              />
            )}
          </AnimatePresence>
        ),
        [validateErrObj]
      )}
    </motion.div>
  );
};

export default InputField;

InputField.propTypes = {
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  OutterClass: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onValueChange: PropTypes.func,
  validateErrObj: PropTypes.object,
};
InputField.defaultProps = {
  labelClass: '',
  inputClass: '',
  OutterClass: '',
  value: '',
  label: '',
  onValueChange: () => {},
  validateErrObj: {},
};
