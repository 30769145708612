import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Typograhpy/Text';
import DropDown from '../Input/Dropdown';

const DropdownField = ({label, onValueChange, options, defaultValue}) => {
  return (
    <div className={'flex flex-col'}>
      <Text value={label} className={`text-primary mb-2`} />
      <DropDown
        options={options}
        onValueChange={onValueChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default DropdownField;

DropdownField.propTypes = {
  label: PropTypes.string,
  onValueChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.object,
};
DropdownField.defaultProps = {
  label: '',
  onValueChange: () => {},
  defaultValue: null,
};
