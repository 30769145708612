import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const Input = ({value, onValueChange, className, type}) => {
  const _onChange = (e) => {
    onValueChange(_.get(e, 'target.value', ''));
  };

  return (
    <input
      type={type}
      value={value}
      onChange={_onChange}
      className={`border-b text-white border-primary bg-transparent outline-none ${className}`}
    />
  );
};

export default Input;

Input.propTypes = {
  value: PropTypes.string,
  _onChange: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};
Input.defaultProps = {
  value: '',
  className: '',
  type: 'string',
  onValueChange: () => {},
};
