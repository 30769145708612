import React, {useState, useEffect, useMemo} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text from '../Typograhpy/Text';
import DropDown from '../Input/Dropdown';
import Input from '../Input/Input';

import codeDataJson from '../../assets/data/codes.json';

const DEFAULT_CODE = '+852';

const PhoneInputField = ({
  label,
  onValueChange,
  inputClass,
  validateErrObj,
}) => {
  const [number, setnumber] = useState('');
  const [code, setcode] = useState(DEFAULT_CODE);

  const codeData = useMemo(() => {
    return _.map(codeDataJson, (i) => {
      return {value: i.dial_code, label: `${i.code} ${i.dial_code}`};
    });
  }, []);

  useEffect(() => {
    onValueChange(`${code} ${number}`);
  }, [number, code, onValueChange]);
  return (
    <motion.div layout className={'flex flex-col'}>
      <Text value={label} className={'text-primary'} />
      <div className={'w-full flex flew-row items-end'}>
        <DropDown
          width={130}
          options={codeData}
          defaultValue={{value: DEFAULT_CODE, label: 'HK +852'}}
          onValueChange={(obj) => {
            setcode(obj.value);
          }}
        />
        <Input
          type={'tel'}
          value={number}
          className={inputClass}
          onValueChange={setnumber}
        />
      </div>
      {useMemo(
        () => (
          <AnimatePresence>
            {validateErrObj?.msg && (
              <Text
                animated
                fieldErr
                value={validateErrObj.msg}
                initial={{
                  y: -30,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  y: -30,
                  opacity: 0,
                }}
              />
            )}
          </AnimatePresence>
        ),
        [validateErrObj]
      )}
    </motion.div>
  );
};
export default PhoneInputField;

PhoneInputField.propTypes = {
  label: PropTypes.string,
  onValueChange: PropTypes.func,
  inputClass: PropTypes.string,
  validateErrObj: PropTypes.object,
};
PhoneInputField.defaultProps = {
  label: null,
  onValueChange: () => {},
  inputClass: '',
  validateErrObj: {},
};
