const isEmail = (email) => {
  if (/^\w+([.-]?\w+[.-]?)*@\w+([.-]?\w+)*(\.\w{2,4})+$/?.test(email)) {
    return true;
  }
  return false;
};

const getEmailDomain = (email) => {
  if (!isEmail(email)) {
    return null;
  }
  return email.replace(/.*@/, '');
};

const isEmptyString = (value) => {
  if (value !== null && (String(value) === '' || String(value).trim() === '')) {
    return true;
  }
  return false;
};

const validator = {
  isEmpty: (value) => {
    if (value == null) {
      return true;
    }
    return isEmptyString(value);
  },
  isEmptyString,
  isEmail,
  getEmailDomain,
  isValidPhone: (number) => {
    if (!number) {
      return false;
    }
    if (String(number).length < 7) {
      return false;
    }
    if (/^\+[1-9]{1,3}[0-9]{7,12}$/.test(String(number))) {
      return false;
    }
    return true;
  },
  isIncludeSpecialChar: (str) => {
    return /[^a-zA-Z0-9 ]/.test(str);
  },
};

export default validator;
